import { styled } from '@hexa-ui/theme';

export const ClampLinesWrapper = styled('p', {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  maxWidth: '100%',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  margin: '0',
});
