/* eslint-disable react-hooks/rules-of-hooks */

import { useValidateCountry } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { useAppHeader, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { deliveryDetailsDefault, IPostDeliveryDetailsResponse } from 'api/DeliveryDetails';
import { IPostDeliveryListResponse } from './DeliveryList.types';

const API_DELIVERY_LIST_URL = '/api/link-last-mile-service/deliveries/v3';
const API_DELIVERY_DETAILS_URL = 'api/link-last-mile-service/delivery-details/v3';

export const useDeliveryList = () => {
  const { api, requestTraceId } = useApi();
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedCountry, selectedVendor } = getAppHeaderConfig;
  const { preferredLanguage } = usePreferredLanguageV2();

  const isBrCountry = useValidateCountry();

  const defaultData: IPostDeliveryListResponse = {
    items: [],
    requestTraceID: '',
  };

  const postDeliveryList = () => {
    const {
      data = defaultData,
      isLoading,
      isError,
    } = useQuery<IPostDeliveryListResponse>(
      ['delivery-list', selectedVendor, selectedCountry],
      async () => {
        const response = await api.post<IPostDeliveryListResponse>({
          url: `${API_DELIVERY_LIST_URL}`,
          data: {
            vendorId: selectedVendor,
          },
          config: {
            headers: { country: selectedCountry, 'Accept-Language': `${preferredLanguage}` },
          },
        });
        return { ...response.data, requestTraceId };
      },
      { enabled: isBrCountry && !!selectedVendor, cacheTime: 0 }
    );
    return { data: data.items, isLoading, isError, requestTraceID: requestTraceId };
  };

  const postDeliveryDetails = (visitId: string) => {
    const {
      data = deliveryDetailsDefault,
      isLoading,
      isError,
    } = useQuery<IPostDeliveryDetailsResponse>(
      ['delivery-details', selectedCountry],
      async () => {
        const response = await api.post<IPostDeliveryDetailsResponse>({
          url: `${API_DELIVERY_DETAILS_URL}/${visitId}`,
          data: {},
          config: {
            headers: { country: selectedCountry, 'Accept-Language': `${preferredLanguage}` },
          },
        });
        return { ...response.data, requestTraceId };
      },
      { enabled: isBrCountry && !!visitId, cacheTime: 0 }
    );

    return { data, isLoading, isError, requestTraceID: requestTraceId, visitId };
  };

  return { postDeliveryList, postDeliveryDetails };
};

export type { IPostDeliveryListResponse };
