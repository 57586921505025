import { createGlobalStyle } from 'styled-components';
import { BREAKPOINT_MOBILE } from '../../constants';

export const HeaderGlobalStyle = createGlobalStyle`
  #mfe-content {
    margin-top: 3.5rem;
    height: calc(-3.5rem + 100vh);

    @media (max-width: ${BREAKPOINT_MOBILE}) {
      margin-top: calc(7rem);
      height: calc(-7rem + 100vh);
    }
  }

  header#mfe-content-header > div > div {
    padding: 18px 0 0;
  }
`;
