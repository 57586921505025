import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  padding: '23px 24px',
  minWidth: '100%',
});

export const LoaderWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$2',
  '& > div:nth-child(1)': {
    flex: 1.5,
  },
  '& > div:nth-child(3)': {
    flex: 0.5,
  },
  height: '24px',
});

export const Spacing = styled('div', {
  flex: 1,
  height: 16,
});

export const LoaderRow = styled('div', {
  display: 'flex',
  gap: '8px',
  marginBottom: '20px',
  variants: {
    showStore: {
      false: {
        margin: '0 0 17px',
      },
    },
  },
});
