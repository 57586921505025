import { Image } from '@hexa-ui/components';
import * as Styles from './DeliveryDetails.styles';
import { DeliveryDetailsItem } from './DeliveryDetails.types';
import { CardStatus } from './sub-components/CartStatus/CardStatus';
import { CardText } from './sub-components/CartText/CartText';

interface OrderDatailsProps<T> {
  isLoading?: boolean;
  data?: T[];
}

export const DeliveryDetails = ({ isLoading, data }: OrderDatailsProps<DeliveryDetailsItem>) => {
  if (isLoading) {
    return <Styles.Paragraph>Carregando</Styles.Paragraph>;
  }

  if (!data) return null;

  return (
    <>
      {data.map((item, index) => (
        <Styles.Card className="hidden-desktop" key={index} border="medium" elevated="minimal">
          <Styles.CardImageWrapper style={{ marginTop: '4px !important' }}>
            <Image src={item.itemImage} variant="fit" height="80px" width="80px" />
            <CardText
              primaryText={item.sku}
              secondText={item.itemName}
              thirdText={item.package}
              fourthText={item.container}
            />
          </Styles.CardImageWrapper>
          <CardStatus primaryText={item.price} secondText={item.quantity} thirdText={item.total} />
        </Styles.Card>
      ))}
    </>
  );
};
