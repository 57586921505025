/* eslint-disable react-hooks/rules-of-hooks */

import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

const currencies = {
  'pt-BR': { currency: 'BRL' },
  'es-MX': { currency: 'MXN' },
  'en-US': { currency: 'USD' },
  'en-ZA': { currency: 'ZAR' },
  'es-AR': { currency: 'ARS' },
  'es-ES': { currency: 'EUR' },
  'en-CA': { currency: 'CAD' },
  'fr-CA': { currency: 'CAD' },
};

export const getFormatCurrency = () => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();

  const formatCurrency = (value: number): string => {
    const effectiveLocale = preferredLanguage || defaultLanguage;
    const currencyInfo = currencies[effectiveLocale];

    const { currency } = currencyInfo;

    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency,
    };

    const formatter = new Intl.NumberFormat(effectiveLocale, options);
    let formattedValue = formatter.format(value);

    formattedValue = formattedValue.replace(/\s/g, '');
    formattedValue = formattedValue.replace(/(\D)(?=\d)/, '$1 ');

    return formattedValue;
  };

  return { formatCurrency };
};
