import { useSegmentAnalytics } from '@hooks';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { EnvConfig, GlobalProvider } from 'providers';
import { Router } from './routes';
import { useAppHeader } from 'admin-portal-shared-services';

export default function App(props: EnvConfig) {
  useSegmentAnalytics(props.SEGMENT_KEY);
  const [, setAppHeaderConfig] = useAppHeader();
  setAppHeaderConfig({
    vendorSelect: false,
    countrySelect: false,
  });

  return (
    <GlobalProvider env={props}>
      <AppHeader />
      <Router />
    </GlobalProvider>
  );
}
