import { PageLoader } from '@components';
import { LazyExoticComponent, ReactElement, Suspense, lazy } from 'react';

export type Modules =
  | 'COMMERCIAL_CONTROL'
  | 'INFORMATION_CENTER'
  | 'DELIVERY_LIST'
  | 'DELIVERY_DETAILS'
  | 'CHAIN'
  | 'STORE'
  | 'CHAIN_DELIVERY'
  | 'STORE_DELIVERY';

type Route = {
  path: string;
  Element: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

type RoutesProps = Record<Modules, Route>;

export const CommercialControlPage = lazy(() => import('views/CommercialControl'));
export const InformationCenterPage = lazy(() => import('views/InformationCenter'));
export const DeliveryList = lazy(() => import('views/Deliveries'));
export const DeliveryDetails = lazy(() => import('views/DeliveryDetails'));
export const OrdersOverviewPage = lazy(() => import('views/Chain'));
export const Store = lazy(() => import('views/Store'));
export const Delivery = lazy(() => import('views/Delivery'));

export const homePath = `/link-admin`;
export const informationCenterPath = `/bees-information-center`;

export const commercialControlPath = `/commercial-control`;
export const deliveryListPath = `${commercialControlPath}/deliveries`;
export const deliveryDetailsPath = `${deliveryListPath}/deliveries-details/:accountId/:vendorId`;
export const chainPath = `${commercialControlPath}/chain`;

export const Routes: RoutesProps = {
  INFORMATION_CENTER: {
    path: `${commercialControlPath}/bees-information-center`,
    Element: InformationCenterPage,
  },
  DELIVERY_LIST: { path: deliveryListPath, Element: DeliveryList },
  COMMERCIAL_CONTROL: { path: commercialControlPath, Element: CommercialControlPage },
  DELIVERY_DETAILS: {
    path: `${deliveryDetailsPath}`,
    Element: DeliveryDetails,
  },
  CHAIN: {
    path: `${chainPath}/:chainName/:chainZoneId`,
    Element: OrdersOverviewPage,
  },
  STORE: {
    path: `${chainPath}/:chainName/:chainZoneId/:pocName/:pocId`,
    Element: Store,
  },
  CHAIN_DELIVERY: {
    path: `${chainPath}/:chainName/:chainZoneId/:distributionCenterId/:id`,
    Element: Delivery,
  },
  STORE_DELIVERY: {
    path: `${chainPath}/:chainName/:chainZoneId/:pocName/:pocId/:distributionCenterId/:id`,
    Element: Delivery,
  },
};

export const modules = [
  { path: '/', Element: () => null },
  Routes.COMMERCIAL_CONTROL,
  Routes.INFORMATION_CENTER,
  Routes.DELIVERY_LIST,
  Routes.CHAIN,
  Routes.STORE,
  Routes.CHAIN_DELIVERY,
  Routes.STORE_DELIVERY,
  Routes.DELIVERY_DETAILS,
];

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <Element />
    </Suspense>
  ),
}));
