/* eslint-disable react-hooks/rules-of-hooks */

import { useValidateCountry } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { IPostDeliveryListResponse } from './DeliveryList.types';

const API_DELIVERIES_DETAILS_URL = '/api/link-last-mile-service/deliveries/v3';

export const useDeliveryList = () => {
  const { api, requestTraceId } = useApi();
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedCountry, vendorOptions } = getAppHeaderConfig;

  const vendorId = vendorOptions?.[0]?.id ?? '';
  const isBrCountry = useValidateCountry();

  const defaultData: IPostDeliveryListResponse = {
    items: [],
    requestTraceID: '',
  };

  const postDeliveries = () => {
    const {
      data = defaultData,
      isLoading,
      isError,
    } = useQuery<IPostDeliveryListResponse>(
      ['deliveryList', vendorId, selectedCountry],
      async () => {
        const response = await api.post<IPostDeliveryListResponse>({
          url: `${API_DELIVERIES_DETAILS_URL}`,
          data: {
            vendorId: vendorId,
          },
          config: {
            headers: { country: selectedCountry },
          },
        });
        return { ...response.data, requestTraceId };
      },
      { enabled: isBrCountry, cacheTime: 0 }
    );
    return { data: data.items, isLoading, isError, requestTraceID: requestTraceId };
  };

  return { postDeliveries };
};

export type { IPostDeliveryListResponse };
