import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { format, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { enUS, ptBR } from 'date-fns/locale';

export const useFormatDate = () => {
  const { preferredLanguage } = usePreferredLanguageV2();

  const formatDateInFull = (propDate: Date | string, isShort = false): string => {
    const date = new Date(propDate);

    if (!isValid(date)) return '';

    const isPTLanguage = preferredLanguage === 'pt-BR';

    const timeZone = isPTLanguage ? 'America/Sao_Paulo' : 'UTC';
    const dateIso = date.toISOString();
    const zonedDate = utcToZonedTime(dateIso, timeZone);
    const formatDateBR = isShort ? "EEEEEE, d 'de' MMM 'de' yyyy" : "EE, d 'de' MMMM 'de' yyyy";
    const formatDateEN = isShort ? 'E, MMM d, yyyy' : 'EEEE, MMMM d, yyyy';
    const formatString = isPTLanguage ? formatDateBR : formatDateEN;
    const locale = isPTLanguage ? ptBR : enUS;

    const formattedDate = format(zonedDate, formatString, { locale });

    return formattedDate.replace(/\b\w/, (match) => match.toUpperCase());
  };

  return { formatDateInFull };
};
